<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_name')"></label>
          <span class="required-star">*</span>
          <input
            id="fullName"
            v-model="promoter.fullName"
            name="Full Name"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.fullName = ''"
          />
          <span v-if="formErrors.fullName" class="invalid-field">{{ formErrors.fullName }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_field_password')"></label>
          <span class="required-star">*</span>
          <input
            v-model="promoter.password"
            type="password"
            class="form-control"
            placeholder
            @keyup="formErrors.password = ''"
          />
          <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_ic_type')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="idType"
            v-model="promoter.idType"
            :searchable="false"
            :options="idTypes"
            label="text"
            track-by="id"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.idType = ''"
          ></Multiselect>
          <span v-if="formErrors.idType" class="invalid-field">{{ formErrors.idType }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_dealer_bank_name')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="multiselect"
            v-model="promoter.bankId"
            placeholder="Select Bank"
            :searchable="true"
            :options="banks"
            label="text"
            track-by="id"
            select-label
            deselect-label
            :loading="getBanksIsLoading"
            @input="formErrors.bankId = ''"
          ></Multiselect>
          <span v-if="formErrors.bankId" class="invalid-field">{{ formErrors.bankId }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_gender')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="multiselect-gender"
            v-model="promoter.genderId"
            placeholder="Select Gender"
            :searchable="true"
            :options="genders"
            label="text"
            track-by="id"
            select-label
            deselect-label
            :loading="isGendersLoading"
            @input="formErrors.genderId = ''"
          ></Multiselect>
          <span v-if="formErrors.genderId" class="invalid-field">{{ formErrors.genderId }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_leader_email')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="multiselect"
            v-model="promoter.promoterLeaderId"
            placeholder="Select Email"
            :searchable="true"
            :options="leaders"
            label="email"
            track-by="id"
            select-label
            deselect-label
            :loading="isLeadersLoading"
          ></Multiselect>

          <span v-if="formErrors.promoterLeaderId" class="invalid-field">
            {{ formErrors.promoterLeaderId }}
          </span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
          <span class="required-star">*</span>
          <input
            v-model="promoter.username"
            type="email"
            class="form-control"
            placeholder
            @keyup="formErrors.username = ''"
          />
          <span v-if="formErrors.username" class="invalid-field">{{ formErrors.username }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="country"
            v-model="promoter.countryId"
            :searchable="false"
            :options="countryOptions"
            label="text"
            track-by="id"
            :loading="isCountriesLoading"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.country = ''; getBanks()"
          ></Multiselect>
          <span v-if="formErrors.country" class="invalid-field">{{ formErrors.country }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_ic_number')"></label>
          <span class="required-star">*</span>
          <input
            id="nationalId"
            v-model="promoter.nationalId"
            v-validate="'alpha_num'"
            name="IC Number"
            type="text"
            class="form-control"
            placeholder
            @keyup="formErrors.nationalId = ''"
          />
          <span v-if="formErrors.nationalId" class="invalid-field">{{ formErrors.nationalId }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_bank_acc_number')"></label>
          <span class="required-star">*</span>
          <input
            id="bankNumber"
            v-model="promoter.bankNumber"
            name="Bank Number"
            type="number"
            class="form-control"
            placeholder
            @keyup="formErrors.bankNumber = ''"
          />
          <span v-if="formErrors.bankNumber" class="invalid-field">{{ formErrors.bankNumber }}</span>
        </div>
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_manager_contact_number')"></label>
          <span class="required-star">*</span>
          <input
            id="phoneNumber"
            v-model="promoter.phoneNumber"
            name="Phone Number"
            type="number"
            class="form-control"
            placeholder
            @keyup="formErrors.phoneNumber = ''"
          />
          <span v-if="formErrors.phoneNumber" class="invalid-field">{{ formErrors.phoneNumber }}</span>
        </div>
      </div>
    </div>
    <div class="w-100 text-right mt-4">
      <button class="btn btn-sm btn-primary" :disabled="isPromoterSubmitting" @click="submitPromoter" v-text="$ml.get('button_submit_text')"></button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { PROMOTER_REGISTRATION_SUBMIT } from "../../store/modules/admin/actions";
import { GENDER_LIST_REQUEST } from "../../store/modules/utility/actions";
import { BANK_LIST_REQUEST } from "../../store/modules/utility/actions";
import { phoneValidate, emailValidate } from "../../utils/helpers";
import { GET_STAFF_LIST_REQUEST } from "../../store/modules/staff/actions";

export default {
  name: "PromoterRegistration",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    countryOptions: {type: Array, default: () => []},
    isCountriesLoading: {type: Boolean, default: false}
  },
  data(){
    return{
      promoter: {
        idType: {text: "IC", id: "I"}
      },
      formErrors: [],
      isPromoterSubmitting: false,
      genders: [],
      isGendersLoading: false,
      banks: [],
      getBanksIsLoading: false,
      leaders: [],
      isLeadersLoading: false,
      idTypes: [
        {text: "IC", id: "I"},
        {text: "Passport", id: "P"}
      ]
    }
  },
  methods: {
    getBanks() {
      let pdata = { countryCode: this.promoter.countryId.code };
      this.getBanksIsLoading = true;
      this.$store.dispatch(BANK_LIST_REQUEST, pdata).then(res => {
        if (res.resultCode === 0) {
          this.banks = res.content;
        }
        this.getBanksIsLoading = false;
      });
    },
    getLeaders() {
      this.leaders = [];
      this.isLeadersLoading = true;
      this.$store.dispatch(GET_STAFF_LIST_REQUEST, { type: "leaders" }).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(leader => {
            if (leader.status) {
              this.leaders.push(leader);
            }
          });
          this.isLeadersLoading = false;
        } else {
          this.isLeadersLoading = false;
        }
      });
    },
    getGenders() {
      this.isGendersLoading = true;
      this.$store.dispatch(GENDER_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.genders = res.content;
          this.isGendersLoading = false;
        } else {
          this.isGendersLoading = false;
        }
      });
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.promoter.username) {
        this.formErrors.username = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.promoter.username){
        if(!emailValidate(this.promoter.username)){
          this.formErrors.username = this.$ml.get('form_dealer_email_invalid');
          error++;
        }
      }

      if (!this.promoter.password) {
        this.formErrors.password = this.$ml.get('form_field_password_required');
        error++;
      }

      if (!this.promoter.countryId) {
        this.formErrors.country = this.$ml.get('form_outlet_country_required');
        error++;
      }

      if (!this.promoter.nationalId) {
        this.formErrors.nationalId = this.$ml.get('form_manager_ic_required');
        error++;
      }

      if (!this.promoter.genderId) {
        this.formErrors.genderId = this.$ml.get('form_salesperson_gender_required');
        error++;
      }

      if (!this.promoter.bankId) {
        this.formErrors.bankId = this.$ml.get('form_salesperson_bank_name_required');
        error++;
      }

      if (!this.promoter.bankNumber) {
        this.formErrors.bankNumber = this.$ml.get('form_salesperson_bank_acc_number_required');
        error++;
      }

      if (!this.promoter.fullName) {
        this.formErrors.fullName = this.$ml.get('form_manager_name_required');
        error++;
      }

      if (!this.promoter.phoneNumber) {
        this.formErrors.phoneNumber = this.$ml.get('form_salesperson_contact_number_required');
        error++;
      }

      if (this.promoter.phoneNumber) {
        if(!phoneValidate(this.promoter.phoneNumber)){
          this.formErrors.phoneNumber = this.$ml.get('invalid_phone_number');
          error++;
        }
      }

      if (!this.promoter.promoterLeaderId) {
        this.formErrors.promoterLeaderId = this.$ml.get("form_promoter_promoter_leader_required");
        error++;
      }

      return !error;
    },
    submitPromoter(e){
      this.isPromoterSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isPromoterSubmitting = false;
        return;
      } else {
        let promoter = {...this.promoter};
        promoter["countryId"] = this.promoter.countryId.id;
        promoter["genderId"] = this.promoter.genderId.id;
        promoter["idType"] = this.promoter.idType.id;
        promoter["bankId"] = this.promoter.bankId.id;
        promoter["promoterLeaderId"] = this.promoter.promoterLeaderId.id;
        this.$store.dispatch(PROMOTER_REGISTRATION_SUBMIT, promoter).then(res => {
          if (res.resultCode === 0) {
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Promoter " + this.promoter.username + " is created",
              timeOut: true
            });
            this.promoter = {};
          }
          this.isPromoterSubmitting = false;
        });
      }
    }
  },
  mounted(){
    this.getGenders();
    this.getLeaders();
  }
}
</script>