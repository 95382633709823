<template>
  <div class="row">
    <div class="col-lg-12"> 
      <div class="form-group flex-grow-1">
        <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
        <span class="required-star">*</span>
        <input
          v-model="admin.email"
          type="email"
          class="form-control"
          placeholder
          @keyup="formErrors.email = ''"
        />
        <span v-if="formErrors.email" class="invalid-field">{{ formErrors.email }}</span>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group flex-grow-1">
        <label class="text-label" v-text="$ml.get('form_field_password')"></label>
        <span class="required-star">*</span>
        <input
          v-model="admin.password"
          type="password"
          class="form-control"
          placeholder
          @keyup="formErrors.password = ''"
        />
        <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group flex-grow-1">
        <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
        <span class="required-star">*</span>
        <Multiselect
          ref="country"
          v-model="admin.country"
          :searchable="false"
          :options="countryOptions"
          label="text"
          track-by="id"
          :loading="isCountriesLoading"
          :allow-empty="false"
          :show-labels="false"
          @input="formErrors.country = ''"
        ></Multiselect>
        <span v-if="formErrors.country" class="invalid-field">{{ formErrors.country }}</span>
      </div>
      <div class="w-100 text-right mt-4">
        <button class="btn btn-sm btn-primary" :disabled="isAdminSubmitting" @click="submitAdmin" v-text="$ml.get('button_submit_text')"></button>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { ADMIN_REGISTRATION_SUBMIT } from "../../store/modules/admin/actions";

export default {
  name: "AdminRegistration",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    countryOptions: {type: Array, default: () => []},
    isCountriesLoading: {type: Boolean, default: false}
  },
  data(){
    return{
      admin: {},
      formErrors: [],
      isAdminSubmitting: false,
    }
  },
  methods: {
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.admin.email) {
        this.formErrors.email = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.admin.email){
        if(!this.validateEmail(this.admin.email)){
          this.formErrors.email = this.$ml.get('form_dealer_email_invalid');
        }
      }

      if (!this.admin.password) {
        this.formErrors.password = this.$ml.get('form_field_password_required');
        error++;
      }

      if (!this.admin.country) {
        this.formErrors.country = this.$ml.get('form_outlet_country_required');
        error++;
      }
      return !error;
    },
    submitAdmin(e){
      this.isAdminSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isAdminSubmitting = false;
        return;
      } else {
        let admin = {};
        admin["username"] = this.admin.email;
        admin["password"] = this.admin.password;
        admin["countryId"] = this.admin.country.id;
        this.$store.dispatch(ADMIN_REGISTRATION_SUBMIT, admin).then(res => {
          if (res.resultCode === 0) {
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Admin " + this.admin.email + " is created",
              timeOut: true
            });
            this.admin = {};
          }
          this.isAdminSubmitting = false;
        });
      }
    }
  },
}
</script>