<template>
  <div id="admin-page">
    <breadcrumb
      title="Admin"
      subtitle="Admin"
      sub-sub-title="Registration"
    />
    <div class="row lnv-main-card">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-5">
              <Multiselect
                ref="multiselect"
                v-model="operation"
                placeholder="Select Process"
                :searchable="true"
                :options="operations"
                label="name"
                track-by="value"
                select-label
                deselect-label
              ></Multiselect>
            </div>
            <div v-if="operation.value === 'admin_registration'">
              <AdminRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <div v-if="operation.value === 'salesperson_registration'">
              <SalespersonRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <div v-if="operation.value === 'checker_registration'">
              <CheckerRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <!-- <div v-if="operation.value === 'salesperson_check'">
              <SalespersonCheck 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div> -->
            <div v-if="operation.value === 'regional_admin_registration'">
              <RegionalAdminRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <div v-if="operation.value === 'regional_registration'">
              <RegionalRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <div v-if="operation.value === 'country_head_registration'">
              <CountryHeadRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <div v-if="operation.value === 'dealer_registration'">
              <DealerRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <div v-if="operation.value === 'promoter_leader_registration'">
              <PromoterLeaderRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <!-- <div v-if="operation.value === 'promoter_check'">
              <PromoterCheck 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div> -->
            <div v-if="operation.value === 'promoter_registration'">
              <PromoterRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <div v-if="operation.value === 'agency_registration'">
              <AgencyRegistration 
                :country-options="countryOptions"
                :is-countries-loading="isCountriesLoading"
              />
            </div>
            <!-- <div v-if="operation.value === 'reset_password'">
              <ResetPassword />
            </div> -->
            <div v-if="operation.value === 'reset_password_web'">
              <ResetPasswordWeb />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import breadcrumb from "../../components/breadcrumb/breadcrumb";
import AdminRegistration from "../../components/admin/admin_registration";
import SalespersonRegistration from "../../components/admin/salesperson_registration";
import CheckerRegistration from "../../components/admin/checker_registration";
// import SalespersonCheck from "../../components/admin/salesperson_check";
import RegionalAdminRegistration from "../../components/admin/regional_admin_registration";
import RegionalRegistration from "../../components/admin/regional_registration";
import CountryHeadRegistration from "../../components/admin/country_head_registration";
import DealerRegistration from "../../components/admin/dealer_registration";
import PromoterLeaderRegistration from "../../components/admin/promoter_leader_registration";
// import PromoterCheck from "../../components/admin/promoter_check";
import PromoterRegistration from "../../components/admin/promoter_registration";
import AgencyRegistration from "../../components/admin/agency_registration";
import ResetPasswordWeb from "../../components/admin/reset_password_web";
import { COUNTRY_LIST_REQUEST } from "../../store/modules/utility/actions";
import Multiselect from "vue-multiselect";

export default {
  name: "Registration",
  components: {
    AdminRegistration,
    SalespersonRegistration,
    CheckerRegistration,
    // SalespersonCheck,
    RegionalAdminRegistration,
    RegionalRegistration,
    CountryHeadRegistration,
    DealerRegistration,
    PromoterLeaderRegistration,
    // PromoterCheck,
    PromoterRegistration,
    AgencyRegistration,
    ResetPasswordWeb,
    breadcrumb: breadcrumb,
    Multiselect
  },
  data(){
    return{
      countryOptions: [],
      isCountriesLoading: false,
      operations: [],
      operation: {name: "Admin Registration", value: "admin_registration"},
    }
  },
  methods: {
    getOperations(){
      this.operations = [
        {name: "Admin Registration", value: "admin_registration"},
        {name: "Salesperson Registration", value: "salesperson_registration"},
        {name: "Checker Registration", value: "checker_registration"},
        // {name: "Salesperson Check", value: "salesperson_check"},
        {name: "Regional Admin Registration", value: "regional_admin_registration"},
        {name: "Regional Registration", value: "regional_registration"},
        {name: "Country Head Registration", value: "country_head_registration"},
        {name: "Dealer Registration", value: "dealer_registration"},
        {name: "Promoter Leader Registration", value: "promoter_leader_registration"},
        // {name: "Promoter Check", value: "promoter_check"},
        {name: "Promoter Registration", value: "promoter_registration"},
        {name: "Agency Registration", value: "agency_registration"},
        {name: "Reset Password", value: "reset_password_web"},
      ]
    },
    getCountries() {
      this.countryOptions = [];
      this.isCountriesLoading = true;
      this.$store.dispatch(COUNTRY_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(country => {
            this.countryOptions.push(country);
          });
        }
        this.isCountriesLoading = false;
      });
    },
  },
  mounted(){
    this.getCountries();
    this.getOperations();
  }
}
</script>
<style scoped>
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
</style>