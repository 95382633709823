<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_salesperson_email')"></label>
          <span class="required-star">*</span>
          <input
            v-model="regional_admin.username"
            type="email"
            class="form-control"
            placeholder
            @keyup="formErrors.username = ''"
          />
          <span v-if="formErrors.username" class="invalid-field">{{ formErrors.username }}</span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_field_password')"></label>
          <span class="required-star">*</span>
          <input
            v-model="regional_admin.password"
            type="password"
            class="form-control"
            placeholder
            @keyup="formErrors.password = ''"
          />
          <span v-if="formErrors.password" class="invalid-field">{{ formErrors.password }}</span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group flex-grow-1">
          <label class="text-label" v-text="$ml.get('form_promoter_country')"></label>
          <span class="required-star">*</span>
          <Multiselect
            ref="country"
            v-model="regional_admin.countryId"
            :searchable="false"
            :options="countryOptions"
            label="text"
            track-by="id"
            :loading="isCountriesLoading"
            :allow-empty="false"
            :show-labels="false"
            @input="formErrors.country = ''"
          ></Multiselect>
          <span v-if="formErrors.country" class="invalid-field">{{ formErrors.country }}</span>
        </div>
      </div>
    </div>
    <div class="w-100 text-right mt-4">
      <button class="btn btn-sm btn-primary" :disabled="isRegionalAdminSubmitting" @click="submitRegionalAdmin" v-text="$ml.get('button_submit_text')"></button>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { NOTIFICATION_SUCCESS } from "../../store/modules/ui/actions";
import { REGIONAL_ADMIN_REGISTRATION_SUBMIT } from "../../store/modules/admin/actions";
import { emailValidate } from "../../utils/helpers";

export default {
  name: "RegionalAdminRegistration",
  components: {
    Multiselect: Multiselect,
  },
  props: {
    countryOptions: {type: Array, default: () => []},
    isCountriesLoading: {type: Boolean, default: false}
  },
  data(){
    return{
      regional_admin: {},
      formErrors: [],
      isRegionalAdminSubmitting: false,
    }
  },
  methods: {
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.regional_admin.username) {
        this.formErrors.username = this.$ml.get('form_dealer_email_required');
        error++;
      }

      if(this.regional_admin.username){
        if(!emailValidate(this.regional_admin.username)){
          this.formErrors.username = this.$ml.get('form_dealer_email_invalid');
          error++;
        }
      }

      if (!this.regional_admin.password) {
        this.formErrors.password = this.$ml.get('form_field_password_required');
        error++;
      }

      if (!this.regional_admin.countryId) {
        this.formErrors.country = this.$ml.get('form_outlet_country_required');
        error++;
      }

      return !error;
    },
    submitRegionalAdmin(e){
      this.isRegionalAdminSubmitting = true;
      if (!this.checkFormValidity(e)) {
        this.isRegionalAdminSubmitting = false;
        return;
      } else {
        let regional_admin = {...this.regional_admin};
        regional_admin["countryId"] = this.regional_admin.countryId.id;
        this.$store.dispatch(REGIONAL_ADMIN_REGISTRATION_SUBMIT, regional_admin).then(res => {
          if (res.resultCode === 0) {
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Regional Admin " + this.regional_admin.username + " is created",
              timeOut: true
            });
            this.regional_admin = {};
          }
          this.isRegionalAdminSubmitting = false;
        });
      }
    }
  },
  mounted(){}
}
</script>